import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  from: Yup.date().test('isFuture', 'Can\'t set future date', (value) => {
    return value ? value < new Date() : true;
  }),
  to: Yup.date().test('isFuture', 'Can\'t set future date', (value) => {
    return value ? value < new Date() : true;
  }).when('from',
      (started, yup) => started && yup.min(started, 'End time can\'t be before start time')),
});
