export type IDatesForStatistic = {
  [key in StatisticPeriods]: IDateRange;
};

export interface IDateRange {
  from: string | null,
  to: string | null,
}

export interface IVenueStatistic {
  [key: string]: any
}

export interface IStatisticParams{
  venueId: string,
}

export enum StatisticPeriods{
  TODAY = 'Today',
  LAST_DAY = 'Last day',
  LAST_WEEK = 'Last week',
  LAST_30_DAYS = 'Last 30 days',
  ALL = 'All time',
  CUSTOM = 'Custom',
}
