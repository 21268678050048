import React from 'react';
import { useQuery } from 'react-query';
import { StyledTable } from '../../../common/components/table';
import { ROUTES } from '../../../common/constants/index';
import { ITableColumn, TableCellType } from '../../../common/types';
import { tableService } from '../../services/table.service';

interface ITablesTableProps {
  venueId: string;
  onUpdateTable: Function;
}

export const TablesTable: React.FC<ITablesTableProps> = ({ venueId, onUpdateTable }) => {
  const { data } = useQuery(
      ROUTES.ROUTES_TABLES,
      () => {
        return tableService.getTables(venueId);
      },
      {
        refetchOnWindowFocus: false,
      },
  );
  const onCopyClick = async (id: string, event: Event) => {
    const text = data?.filter((table) => table.id === id)[0].nfcCode?.code!;
    await navigator.clipboard.writeText(text);
    const target = (event.target! as HTMLButtonElement);
    const buttonInitialText = target.textContent;
    target.textContent = 'Copied!';
    setTimeout(() => {
      target.textContent = buttonInitialText;
    }, 2000);
  };

  const columns: ITableColumn[] = [
    {
      title: 'Table Name',
      key: 'name',
      type: TableCellType.Text,
    },
    {
      title: 'NFC tag',
      key: 'nfcCode.code',
      type: TableCellType.Button,
      onClick: onCopyClick,
    },
    {
      title: 'Update',
      key: 'update',
      type: TableCellType.Button,
      onClick: onUpdateTable,
    },
  ];

  return (
    <StyledTable
      columns={columns}
      rows={data || []}
    ></StyledTable>
  );
};
