/* eslint-disable react/react-in-jsx-scope */
import { FormikProvider, useFormik } from "formik";
import { Styled } from "./venue-modal.styled";
import { updateValidationSchema } from "../../utils/venue-validation-schema";
import { IVenueFormValues, IVenue } from "../../types";
import { ImageDropzone } from "../../../common/components/dropzone";
import { useEffect, useState } from "react";
import { UPDATE_INPUTS } from "../../constants";

export interface IUpdateVenueModalProps {
  toCloseModal: Function;
  onSubmit: Function;
  onDelete: Function;
  venue: IVenue;
}

export const UpdateVenueModal: React.FC<IUpdateVenueModalProps> = ({
  toCloseModal,
  onSubmit,
  onDelete,
  venue,
}) => {
  const [loadedIcon, setLoadedIcon] = useState(venue.media || "");
  const formik = useFormik({
    initialValues: {
      venueName: venue.name,
      venueOwnerName: venue.venueOwner.name,
      ownerEmail: venue.venueOwner.email,
      password: "",
      media: venue.media,
      description: venue.description,
      address: venue.address,
      openHours: venue.openHours,
      callWaiter: venue.callWaiter,
      acceptOrders: venue.acceptOrders,
    },
    onSubmit: ({
      venueOwnerName,
      venueName,
      description,
      ownerEmail,
      password,
      media,
      address,
      openHours,
      callWaiter,
      acceptOrders,
    }: IVenueFormValues) => {
      const newVenue: IVenue = {
        id: venue.id,
        name: venueName,
        description,
        address,
        openHours,
        venueOwner: {
          name: venueOwnerName,
          email: ownerEmail,
        },
        media,
        callWaiter,
        acceptOrders,
      };
      if (password) {
        newVenue.venueOwner.password = password;
      }
      onSubmit(newVenue);

      toCloseModal();
    },
    validationSchema: updateValidationSchema,
  });

  useEffect(() => {
    setLoadedIcon(venue?.media || "");
  }, [venue]);

  useEffect(() => {
    formik.setFieldValue("media", loadedIcon);
  }, [loadedIcon]);

  const setFormikMedia = () => formik.setFieldValue("media", loadedIcon);

  const deleteVenue = () => {
    if (venue && venue.id) {
      onDelete(venue?.id);
      toCloseModal();
    }
  };
  const clearImage = () => setLoadedIcon("");
  return (
    <FormikProvider value={formik}>
      <Styled.Form onSubmit={formik.handleSubmit}>
        <Styled.FormContent>
          <Styled.InputsContainer>
            {UPDATE_INPUTS.map(
              ({ type, text, name, placeholder, Component }) => (
                <Component
                  key={name}
                  type={type}
                  text={text}
                  name={name}
                  placeholder={placeholder}
                />
              )
            )}
          </Styled.InputsContainer>
          <Styled.DropzoneContainer>
            <h2>Upload image</h2>
            {loadedIcon ? (
              <Styled.Preview src={loadedIcon} />
            ) : (
              <ImageDropzone
                setFormikMedia={setFormikMedia}
                setFileData={setLoadedIcon}
              />
            )}
            <Styled.Button type="button" onClick={clearImage}>
              Clear image
            </Styled.Button>
          </Styled.DropzoneContainer>
        </Styled.FormContent>
        <Styled.ButtonsContainer>
          <Styled.Button type="submit">Submit</Styled.Button>
          <Styled.Button onClick={deleteVenue}>Delete Venue</Styled.Button>
        </Styled.ButtonsContainer>
      </Styled.Form>
    </FormikProvider>
  );
};
