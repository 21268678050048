import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Input = styled.input<{ width?: string, isErrorExists?: boolean, isEdit?: boolean }>`
  box-sizing: border-box;
  display: block;
  width: ${({ width }) => width || '300px'};
  margin: 0;
  height: 25px;
  padding: ${({ theme }) => theme.spaces.sm} 5px;
  font-size: 18px;
  border: none;
  outline: none;
  border: 1px solid;
  margin-bottom: ${({ isEdit, isErrorExists, theme }) => (!isEdit && isErrorExists) || isEdit ? '0' : theme.spaces.sm};
  border-color: ${({ theme }) => theme.colors.not_active};
  &:focus {
    border-color: ${({ theme }) => theme.colors.focused};
    box-shadow: 0 0 10px rgba(0, 191, 255,0.5);
  }
`;

const Label = styled.label`
  display: block;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 15px;
  font-size: 24px;
`;

const ErrorBox = styled.div<{ width?: string, isEdit?: boolean }>`
  width: ${({ width }) => width || '300px'};
  text-align: left;
  padding-left: ${({ theme }) => theme.spaces.xxs};;
  margin-bottom: ${({ theme }) => theme.spaces.xs};
  margin-top: ${({ theme }) => theme.spaces.xs};
  position: ${({ isEdit }) => !isEdit ? 'initial' : 'absolute'};
  top: ${({ isEdit }) => !isEdit ? '0' : '85%'};
  left: 0;
`;

export const Styled = {
  Input,
  Label,
  ErrorBox,
  Wrapper,
};
