/* eslint-disable react/react-in-jsx-scope */
import { FormikProvider, useFormik } from 'formik';
import { Styled } from './venue-modal.styled';
import { createValidationSchema } from '../../utils/venue-validation-schema';
import { IVenueFormValues, IVenue } from '../../types';
import { ImageDropzone } from '../../../common/components/dropzone';
import { useEffect, useState } from 'react';
import { CREATE_INPUTS } from '../../constants';

export interface ICreateVenueModalProps {
  toCloseModal: Function;
  onSubmit: Function;
};

export const CreateVenueModal: React.FC<ICreateVenueModalProps> = ({ toCloseModal, onSubmit }) => {
  const [loadedIcon, setLoadedIcon] = useState('');

  const formik = useFormik({
    initialValues: {
      venueName: '',
      description: '',
      address: '',
      openHours: '',
      venueOwnerName: '',
      ownerEmail: '',
      countTables: 0,
      password: '',
      media: '',
      callWaiter:false,
      acceptOrders:false
    },
    onSubmit: ({
      description,
      countTables,
      venueName,
      ownerEmail,
      venueOwnerName,
      password,
      media,
      address,
      openHours,
      callWaiter,
      acceptOrders
    }: IVenueFormValues) => {
      const newVenue: IVenue = {
        countTables,
        name: venueName,
        description,
        address,
        openHours,
        venueOwner: {
          name: venueOwnerName,
          email: ownerEmail,
          password,
        },
        media,
        id: '',
        callWaiter,
        acceptOrders
      };
      onSubmit(newVenue);
      toCloseModal();
    },
    validationSchema: createValidationSchema,
  });

  useEffect(() => {
    formik.setFieldValue('media', loadedIcon);
  }, [loadedIcon]);

  const setFormikMedia = () => formik.setFieldValue('media', loadedIcon);
  const clearImage = () => setLoadedIcon('');

  return (
    <FormikProvider value={formik}>
      <Styled.Form onSubmit={formik.handleSubmit}>
        <Styled.FormContent>
          <Styled.InputsContainer>
            {CREATE_INPUTS.map(({ type, text, name, placeholder, Component, min }:any) => (
              <Component
                key={name}
                type={type}
                text={text}
                name={name}
                placeholder={placeholder}
                min={min}
              />))}
          </Styled.InputsContainer>
          <Styled.DropzoneContainer>
            <h2>Upload image</h2>
            {loadedIcon ? (
          <Styled.Preview src={loadedIcon} />
        ) : (
          <ImageDropzone setFormikMedia={setFormikMedia} setFileData={setLoadedIcon} />
        )}
            <Styled.Button type="button" onClick={clearImage}>Clear image</Styled.Button>
          </Styled.DropzoneContainer>
        </Styled.FormContent>
        <Styled.ButtonsContainer>
          <Styled.Button type='submit'>Submit</Styled.Button>
        </Styled.ButtonsContainer>
      </Styled.Form>
    </FormikProvider>
  );
};
