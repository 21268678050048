import styled from 'styled-components';

const Form = styled.form`
  margin-top: ${({ theme }) => theme.spaces.sm};
  display: flex;
  &>div{
    max-width: 200px;
  }
  & input{
    padding: ${({ theme }) => theme.spaces.sm} 5px;
    font-size: ${({ theme }) => theme.fonts.md};
    font-family: 'Roboto';
    width: 200px;
  }
  &>*:not(:first-child){
    margin-left: ${({ theme }) => theme.spaces.xs};
  }
`;

const Button = styled.button`
  display: block;
  border: 2px solid;
  border-color: ${({ theme }) => theme.colors.white};
  border-radius: 7px;
  max-width: 150px;
  min-width: 150px;
  height: 45px;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;
export const Styled = {
  Form,
  Button,
};
