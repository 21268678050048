import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const SideBarSection = styled.div`
  box-sizing: border-box;
  height: 100vh;
  min-width: 250px;
  max-width: 250px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.black}
`;

const Link = styled(NavLink)`
  display: block;
  width: auto;
  height: 25px;
  padding-left: 10px;
  margin-top: 30px;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.not_active};
  &:hover { color: ${({ theme }) => theme.colors.white}; } 
  &.active {
    font-weight: 700;
    color: ${( { theme }) => theme.colors.white}
  }
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.secondary}
`;

export const Styled = {
  SideBarSection,
  Link,
  Title,
};
