import React from 'react';
import { useQuery } from 'react-query';
import { StyledTable } from '../../../common/components/table';
import { ROUTES } from '../../../common/constants/index';
import { ITableColumn, TableCellType } from '../../../common/types';
import { venueService } from '../../services/venue.service';

interface IVenueTableProps {
  additionalColumns?: ITableColumn[];
}

export const VenueTable: React.FC<IVenueTableProps> = ({ additionalColumns = [] }) => {
  const { data } = useQuery(
      ROUTES.ROUTES_VENUES,
      () => {
        return venueService.getVenues();
      },
  );

  const columns: ITableColumn[] = [
    { title: 'Name',
      key: 'name',
      type: TableCellType.Text },
    {
      title: 'Number of tables',
      key: 'tablesCount.tables',
      type: TableCellType.Text,
    },
    {
      title: 'Owner Name',
      key: 'venueOwner.name',
      type: TableCellType.Text,
    },
    {
      title: 'Owner Email',
      key: 'venueOwner.email',
      type: TableCellType.Text,
    },
    ...additionalColumns,
  ];

  return (
    <StyledTable
      columns={columns}
      rows={data || []}
    ></StyledTable>
  );
};
