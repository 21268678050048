type order = 'ASC' | 'DESC'

export const sortArray = <T extends object, R extends keyof T>(arr: T[], param: R, order: order = 'ASC'): T[] => {
  return arr?.sort((item1, item2) => {
    if (item1[param] > item2[param]) {
      return order === 'ASC' ? 1 : -1;
    } else if (item1[param] < item2[param]) {
      return order === 'DESC' ? 1 : -1;
    }
    return 0;
  });
};
