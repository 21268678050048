import styled from 'styled-components';

interface IButton{
  isActive?: boolean
}
const TabsWrapper = styled.div`
`;

const Button = styled.button<IButton>`
  border: 2px solid;
  border-color: ${({ theme, isActive }) => isActive ? theme.colors.white : theme.colors.black};
  border-radius: 7px;
  margin-top: ${({ theme }) => theme.spaces.md};
  margin-right: ${({ theme }) => theme.spaces.xs};
  margin-left: auto;
  max-width: 150px;
  min-width: 150px;
  height: 45px;
  background-color: ${({ theme, isActive }) => isActive ? theme.colors.black : theme.colors.white};
  color: ${({ theme, isActive }) => isActive ? theme.colors.white : theme.colors.black};
  cursor: pointer;
`;

export const Styled = {
  TabsWrapper,
  Button,
};
