import dayjs from 'dayjs';

export const useDates = () => {
  const today = dayjs().hour(0).minute(0).second(0).millisecond(0).toISOString();
  const yesterdayFrom = dayjs().subtract(1, 'day').hour(0).minute(0).second(0).millisecond(0).toISOString();
  const yesterdayTo = dayjs().subtract(1, 'day').hour(23).minute(59).second(59).millisecond(0).toISOString();
  const weekFrom = dayjs().subtract(8, 'day').hour(23).minute(59).second(59).millisecond(0).toISOString();
  const weekTo = yesterdayTo;
  const monthFrom = dayjs().subtract(31, 'day').hour(23).minute(59).second(59).millisecond(0).toISOString();
  const monthTo = yesterdayTo;

  return { today, yesterdayFrom, yesterdayTo, weekFrom, weekTo, monthFrom, monthTo };
};
