import { AxiosError } from 'axios';
import { IErrorResponse, IHttpClient, IHttpConfig, IResponse } from '../../../types';
import { IMap } from '../types';

export class HttpService implements IHttpClient {
  constructor(
    private fetchingService: IHttpClient,
    private baseUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:5000',
  ) { }

  public createQueryLink(base: string, args: IMap) {
    let url = `${base}?`;
    Object.keys(args).map((parameter, index) => {
      url = args[parameter] ? url + `${index > 0 ? '&' : ''}${parameter}=${args[parameter]}` : url;
    });
    return url;
  }

  private getFullApiUrl(url: string) {
    return `${this.baseUrl}/${url}`;
  }

  public get<T>(url: string, config?: IHttpConfig) {
    return this.fetchingService.get<IResponse<T>>(this.getFullApiUrl(url), config).then((result) => {
      if (result) {
        this.checkResponseStatus(result);
        return result.data;
      }
    }).catch(this.errorHandler);
  }

  public post<T, D>(url: string, data: D, config?: IHttpConfig) {
    return this.fetchingService.post<IResponse<T>, D>(this.getFullApiUrl(url), data, config).then((result) => {
      if (result) {
        this.checkResponseStatus(result);
        return result.data;
      }
    }).catch(this.errorHandler);
  }

  public put<T, D>(url: string, data: D, config?: IHttpConfig) {
    return this.fetchingService.put<IResponse<T>, D>(this.getFullApiUrl(url), data, config).then((result) => {
      if (result) {
        this.checkResponseStatus(result);
        return result.data;
      }
    }).catch(this.errorHandler);
  }

  public delete<T>(url: string, config?: IHttpConfig) {
    return this.fetchingService.delete<IResponse<T>>(this.getFullApiUrl(url), config).then((result) => {
      if (result) {
        this.checkResponseStatus(result);
        return result.data;
      }
    }).catch(this.errorHandler);
  }

  private errorHandler(error: AxiosError<IErrorResponse>) {
    const errorResponse = error.response;

    const errorData: IErrorResponse = {
      statusCode: errorResponse?.data.statusCode || 404,
      message: errorResponse?.data.message || 'Oops, something went wrong!',
    };

    const event = new CustomEvent('http-error', { detail: errorData });
    document.dispatchEvent(event);
  }

  private checkResponseStatus<T>(result: IResponse<T>) {
    if (result.status >= 400 && result.status < 600) {
      const errorData = {
        response: {
          status: result.status,
          data: result.data,
        },
      };

      throw new Error(JSON.stringify(errorData));
    }
  }
}
