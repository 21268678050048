import React, { FC } from 'react';
import { StatisticPeriods } from '../../types';
import { getStatisticsTabs } from '../../utils/get-statistics-tabs';
import { Styled } from './statistic-tabs.styled';

interface IStatisticTabsPops {
  statisticPeriod: StatisticPeriods,
  setStatisticPeriod: (statisticPeriod: StatisticPeriods) => void
}

export const StatisticTabs:FC<IStatisticTabsPops> = ({ statisticPeriod, setStatisticPeriod }) => {
  const tabs = getStatisticsTabs(statisticPeriod, setStatisticPeriod);

  const Tabs = tabs.map((tab: any ) => {
    return (
      <Styled.Button
        key={tab.name}
        isActive={tab.isActive}
        onClick={tab.onClick}
      >
        {tab.name}
      </Styled.Button>
    );
  });
  return (
    <Styled.TabsWrapper>
      {Tabs}
    </Styled.TabsWrapper>
  );
};
