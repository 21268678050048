import * as Yup from 'yup';
import { getNameValidator } from '../../common/utils/get-name-validator';

export const validationSchema = Yup.object().shape({
  type: getNameValidator('Category'),
  media: Yup.string()
      .required('Image is required')
      .min(1, 'Invalid image'),
  menuItems: Yup.array(),
});
