import { StatisticPeriods } from '../types';

export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

export const getStatisticsTabs = (
    interval: StatisticPeriods,
    setStatisticPeriod: (statisticPeriod: StatisticPeriods) => void) => {
  const tabs: any = [];
  for (const value of enumKeys(StatisticPeriods)) {
    if (StatisticPeriods[value] !== StatisticPeriods.CUSTOM) {
      tabs.push({
        name: StatisticPeriods[value],
        isActive: interval === StatisticPeriods[value],
        onClick: () => setStatisticPeriod(StatisticPeriods[value]),
      });
    }
  }
  return tabs;
};
