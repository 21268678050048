import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Wrapper, Container, StyledTableCell } from '../table/table.styled';
import { StyledTableRow } from '../table-row';
import { IMap, ITableColumn } from '../../types';

export interface IStyledTableProps {
  columns: ITableColumn[],
  rows: IMap[],
  keyProp?: string,
};

export const StyledTable: React.FC<IStyledTableProps> = ({ columns, rows, keyProp = 'id' }) => {
  const row = rows.map((item) => (
    <StyledTableRow key={item[keyProp]} columns={columns} item={item} keyProp={keyProp}/>
  ));

  return (
    <Wrapper>
      <TableContainer component={Container}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <StyledTableCell key={index} style={column.style}>
                  {column.title}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            { row }
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
};
