import styled from 'styled-components';
import deleteIcon from '../../../../assets/garbage.png';

const Form = styled.form`
  display: block;
  margin: 0 auto;
  height: auto;
  color: ${({ theme }) => theme.colors.black};
  padding: 50px;
`;

const Button = styled.button`
  display: block;
  border: 2px solid;
  border-color: ${({ theme }) => theme.colors.white};
  border-radius: 7px;
  min-width: 150px;
  max-width: 200px;
  height: 45px;
  margin-top: 20px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
`;

const DeleteButton = styled.div`
  background-image: url(${deleteIcon});
  height: 30px;
  width: 30px;
  background-size: cover;
  margin: auto;
  cursor: pointer;
  margin-left: 100px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  & button:first-child{
    margin-right: ${({ theme }) => theme.spaces.sm};
  }
`;

const FormContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const DropzoneContainer = styled.div`
    max-width: 318px;
  & h2{
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-bottom:  ${({ theme }) => theme.spaces.xs};
    font-size: ${({ theme }) => theme.fonts.xl};
    font-weight: 400;
  }
  & button{
    margin: ${({ theme }) => theme.spaces.sm} auto 0;
  }
`;
const InputsContainer = styled.div`
`;

const Preview = styled.img`
  width: 318px;
  height: 160px;
`;

export const Styled = {
  Form,
  Button,
  DeleteButton,
  ButtonsContainer,
  FormContent,
  DropzoneContainer,
  InputsContainer,
  Preview,
};
