import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 1024px;
  height: 100vh;
`;

export const Styled = {
  Wrapper,
};
