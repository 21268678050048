import { getNameValidator } from '../../common/utils/get-name-validator';
import * as Yup from 'yup';
import { getNonRequiredPasswordValidator, getPasswordValidator } from '../../common/utils/get-password-validator';
import { getTablesCountValidator } from '../../common/utils/get-tables-count-validator';
import { getEmailValidator } from '../../common/utils/get-email-validator';

export const updateValidationSchema = Yup.object().shape({
  venueName: getNameValidator(),
  description: getNameValidator('Description'),
  address: getNameValidator('Address'),
  openHours: getNameValidator('Open hours'),
  venueOwnerName: getNameValidator('Owner name'),
  ownerEmail: getEmailValidator(),
  password: getNonRequiredPasswordValidator(),
});

export const createValidationSchema = Yup.object().shape({
  venueName: getNameValidator(),
  description: getNameValidator('Description'),
  address: getNameValidator('Address'),
  openHours: getNameValidator('Open hours'),
  venueOwnerName: getNameValidator('Owner name'),
  ownerEmail: getEmailValidator(),
  countTables: getTablesCountValidator(),
  password: getPasswordValidator(),
});
