import styled from 'styled-components';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 450px;
  height: auto;
  color: ${({ theme }) => theme.colors.black};
`;

const SubTitle = styled.h2`
  padding-bottom: 12px;
`;

const Button = styled.button`
  border: 2px solid;
  border-color: ${({ theme }) => theme.colors.white};
  border-radius: 7px;
  min-width: 150px;
  max-width: 200px;
  height: 45px;
  margin: ${({ theme }) => `${theme.spaces.sm} ${theme.spaces.xs}`};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
`;

const ButtonsContainer = styled.div`
  display: flex;
`;

const Preview = styled.img`
  width: 318px;
  height: 160px;
`;

export const Styled = {
  Form,
  Button,
  ButtonsContainer,
  Preview,
  SubTitle,
};
