import React from 'react';
import { SideBar } from '../common/components/sidebar';
import { VenueManagement } from './components/venue-management';
import { Styled } from './venue-page.styled';

export const VenuePage = () => {
  return (
    <Styled.Wrapper>
      <SideBar />
      <VenueManagement />
    </Styled.Wrapper>
  );
};
