import styled from 'styled-components';
import arrowLeft from '../../assets/arrow-left.png';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 1024px;
  height: 100vh;
  overflow-y: scroll;
`;

const StatisticWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
`;

const TopSection = styled.div`
  padding-top: ${({ theme }) => theme.spaces.md};
  padding-left: ${({ theme }) => theme.spaces.xl};
`;

const BackButton = styled.div`
  background-image: url(${arrowLeft});
  height: 40px;
  width: 40px;
  background-size: cover;
  cursor: pointer;
`;

export const Styled = {
  Wrapper,
  StatisticWrapper,
  BackButton,
  TopSection,
};
