import { getIn, useFormikContext } from 'formik';
import React from 'react';
import { Styled } from './textarea.styled';

export interface ITextareaProps {
  text?: string;
  name: string;
  placeholder?: string;
  width?: string;
};

export const Textarea: React.FC<ITextareaProps> = ({ text, name, placeholder, width }) => {
  const { values, handleChange, errors, touched } = useFormikContext();
  const isErrorExists = getIn(errors, name) && getIn(touched, name);

  return (
    <Styled.Wrapper>
      {text && <Styled.Label htmlFor={name}>{text}</Styled.Label>}
      <Styled.Textarea
        id={name}
        name={name}
        onChange={handleChange(name)}
        value={getIn(values, name)}
        placeholder={placeholder}
        width={width}
        isErrorExists={isErrorExists}
      />
      {isErrorExists && (
        <Styled.ErrorBox>{ getIn(errors, name) }</Styled.ErrorBox>
      )}
    </Styled.Wrapper>
  );
};
