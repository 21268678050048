/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpService } from '../../common/services/http.service';
import { ITable, ITableObject } from '../types';
import { HttpServiceFactory } from '../../common/services';
import { IResponse } from '../../../types';
import { EnhancedWithAuthHttpService } from '../../common/services/http-auth.service';
import { ROUTES } from '../../common/constants';

export class TableService {
  private httpService: EnhancedWithAuthHttpService;

  constructor(private httpFactory: HttpServiceFactory) {
    this.httpService = this.httpFactory.createAuthHttpService();
  }

  public getTables(id: string): Promise<ITable[] | void> {
    return this.httpService.get<ITable[]>(`${ROUTES.ROUTES_TABLES}/venue/${id}`);
  }

  public createTable(id: string, tableName: string): Promise<ITable | void> {
    return this.httpService.post(ROUTES.ROUTES_TABLES, { id, tableName });
  }

  public updateTable(id: string, tableName: string): Promise<ITable | void> {
    return this.httpService.put(`${ROUTES.ROUTES_TABLES}/${id}`, { tableName });
  }

  public deleteTable(id: string): Promise<IResponse<ITable> | void> {
    return this.httpService.delete(`${ROUTES.ROUTES_TABLES}/${id}`);
  }
}

export const tableService = new TableService(new HttpServiceFactory());
