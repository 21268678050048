import styled from 'styled-components';
import { TableCell, Paper } from '@material-ui/core';

export const StyledTableCell = styled(TableCell)`
 && {
  height: 80px;
  background-color: #F5F5F5;
  font-weight: bold;
  max-width: 500;
  }
`;

export const Container = styled(Paper)`
  border: 1px solid;
`;

export const ButtonTableCell = styled(TableCell)`
&& {
  width: 100px;
  padding-left: 0;
  }
`;

export const Button = styled.button`
  border: 2px solid;
  border-color: ${({ theme }) => theme.colors.white};
  border-radius: 7px;
  width: 100%;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spaces.md} ${({ theme }) => theme.spaces.lg};
  max-width: 1800px;
`;
