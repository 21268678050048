import React from 'react';
import { useHistory } from 'react-router';
import { StyledTable } from '../../../common/components/table';
import { ITableColumn, TableCellType } from '../../../common/types';
import { useQueryDataByPeriod } from '../../hooks/useQueryDataByPeriod';
import { statisticService } from '../../services/statistic.service';
import { IDateRange, IVenueStatistic } from '../../types';
import { StatisticPeriods } from '../../types';
import { converseAmountToFixed } from '../../utils/amount-to-fixed';
import { sortArray } from '../../utils/sortArray';

interface IVenueTableProps {
  statisticPeriod: StatisticPeriods,
  customStatisticPeriod: IDateRange,
  additionalColumns?: ITableColumn[];
}

export const VenueStatisticTable: React.FC<IVenueTableProps> = ({
  statisticPeriod,
  customStatisticPeriod,
  additionalColumns = [],
}) => {
  const history = useHistory();

  const { data } = useQueryDataByPeriod({
    name: 'venues-statistic',
    venueId: null,
    statisticPeriod,
    customStatisticPeriod,
    fetchMethod: statisticService.getVenueStatistic.bind(statisticService).bind(statisticService),
  });

  const onWaitersStatisticClick = (id: string) => {
    history.push(`/statistics/${id}/waiters`);
  };
  const onTablesStatisticClick = (id: string) => {
    history.push(`/statistics/${id}/tables`);
  };
  const onDeletedStatisticClick = (id: string) => {
    history.push(`/statistics/${id}/deleted`);
  };

  const columns: ITableColumn[] = [
    {
      title: 'Name',
      key: 'venue_name',
      type: TableCellType.Text,
    },
    {
      title: 'Total Income',
      key: 'amount',
      type: TableCellType.Text,
    },
    {
      title: 'Waiter\'s statistic',
      key: 'waiter',
      type: TableCellType.Button,
      onClick: onWaitersStatisticClick,
    },
    {
      title: 'Table\'s statistic',
      key: 'table',
      type: TableCellType.Button,
      onClick: onTablesStatisticClick,
    },
    {
      title: 'Deleted orders',
      key: 'deleted',
      type: TableCellType.Button,
      onClick: onDeletedStatisticClick,
    },
    ...additionalColumns,
  ];

  const sortedData = sortArray(converseAmountToFixed(data) as IVenueStatistic[], 'venue_id', 'ASC');

  return (
    <StyledTable
      columns={columns}
      rows={sortedData || []}
      keyProp="venue_id"
    ></StyledTable>
  );
};

