import React from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { Styled } from './error-page.styled';
import { ErrorMessage } from './constants';

export const ErrorPage: React.FC = () => {
  const location = useLocation();
  const { statusCode, message } = qs.parse(location.search, { ignoreQueryPrefix: true });
  return (
    <Styled.Wrapper>
      <Styled.Status>{statusCode || 404}</Styled.Status>
      <h4>{message || ErrorMessage.DEFAULT}</h4>
    </Styled.Wrapper>
  );
};
