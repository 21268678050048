import React from 'react';
import { useParams } from 'react-router';
import { StyledTable } from '../../../common/components/table';
import { ITableColumn, TableCellType } from '../../../common/types';
import { useQueryDataByPeriod } from '../../hooks/useQueryDataByPeriod';
import { statisticService } from '../../services/statistic.service';
import { IDateRange, IStatisticParams, IVenueStatistic } from '../../types';
import { StatisticPeriods } from '../../types';
import { converseAmountToFixed } from '../../utils/amount-to-fixed';
import { sortArray } from '../../utils/sortArray';

interface IVenueTableProps {
  statisticPeriod: StatisticPeriods,
  customStatisticPeriod: IDateRange,
  additionalColumns?: ITableColumn[];
}

export const DeletedStatisticTable: React.FC<IVenueTableProps> = ({
  statisticPeriod,
  customStatisticPeriod,
  additionalColumns = [],
}) => {
  const { venueId } = useParams<IStatisticParams>();

  const { data } = useQueryDataByPeriod({
    name: 'deleted-statistic',
    venueId,
    statisticPeriod,
    customStatisticPeriod,
    fetchMethod: statisticService.getDeletedStatistic.bind(statisticService),
  });

  const sortedData = sortArray(converseAmountToFixed(data) as IVenueStatistic[], 'order_numberId', 'DESC');

  const columns: ITableColumn[] = [
    {
      title: 'Order id',
      key: 'order_numberId',
      type: TableCellType.Text,
    },
    {
      title: 'Total Cost',
      key: 'amount',
      type: TableCellType.Text,
    },
    ...additionalColumns,
  ];

  return (
    <StyledTable
      columns={columns}
      rows={sortedData || []}
      keyProp="order_numberId"
    ></StyledTable>
  );
};

