import React, { FC } from 'react';
import { Button } from '../../../../common/components/button';
import { Styled } from './edit-modal-item.styled';
import { IDishOption, IDishPayload } from '../../../types';

interface IEditModalItemDisableProps {
    option: IDishOption | IDishPayload,
    onDelete: Function,
    onEnableEditMode: Function,
    isEditModeEnable:boolean,
}

export const EditModalItemDisable:FC<IEditModalItemDisableProps> = ({ option, onDelete, onEnableEditMode }) => {
  const onDeleteHandler = () => {
    onDelete({ id: option.id });
  };

  const onHandleEnableEditMode = () => onEnableEditMode();

  return (
    <Styled.ItemWrapper>
      <Styled.Name>{option.name}</Styled.Name>
      <Styled.Price>{option.price}</Styled.Price>
      <Styled.Edit>
        <Button
          onClick={onHandleEnableEditMode}
          text={'Edit'}
          width="70px"
          height="34px"
          type="submit"
        />
      </Styled.Edit>
      <Styled.Delete onClick={onDeleteHandler}></Styled.Delete>
    </Styled.ItemWrapper>
  );
};
