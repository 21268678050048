import React, { useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Styled } from './login-form.styled';
import { useHistory } from 'react-router-dom';
import { validationSchema } from '../../utils/validation-schema';
import { authService } from '../../services/user.service';
import { FormInput } from '../../../common/components/form-input';
import { StoreKeys } from '../../../common/constants';
import { ILoginResponse } from '../../types';

const initialValues = {
  email: '',
  password: '',
};

export const LoginForm = () => {
  const history = useHistory();

  const [errorAccess, setErrorAccess] = useState(false);
  const setLocalToken = (data: string) => {
    localStorage.setItem(StoreKeys.Token, data);
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async ({ email, password }) => {
      try {
        const result = await authService.loginUser(email, password) as ILoginResponse;
        if (result.token ) {
          setLocalToken(result.token);
          history.push('/');
        }
      } catch (err) {}
      setErrorAccess(false);
    },
    validationSchema: validationSchema,
  });

  return (
    <FormikProvider value={formik}>
      <Styled.Form onSubmit={formik.handleSubmit}>
        <Styled.NewFormInput
          text='Please Log In'
          name='email'
          type='email'
          placeholder='email@example.com'
        />
        <FormInput
          text=''
          name='password'
          type='password'
          placeholder='Password'
        />
        {errorAccess && (
          <Styled.ErrorBox>
            {'Incorrect email or password, please try again'}
          </Styled.ErrorBox>
        )}
        <Styled.Button type='submit'>LOG IN</Styled.Button>
      </Styled.Form>
    </FormikProvider>
  );
};
