/* eslint-disable react/react-in-jsx-scope */
import { FormikProvider, useFormik } from 'formik';
import { Styled } from './table-modal.styled';
import { validationSchema } from '../../utils/table-validation-schema';
import { FormInput } from '../../../common/components/form-input';

export interface ICreateTableModalProps {
  toCloseModal: Function;
  onSubmit: Function;
}

export const CreateTableModal: React.FC<ICreateTableModalProps> = ({ toCloseModal, onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      tableName: '',
    },
    onSubmit: ({ tableName }) => {
      onSubmit(tableName);
      toCloseModal();
    },
    validationSchema: validationSchema,
  });

  return (
    <FormikProvider value={formik}>
      <Styled.Form onSubmit={formik.handleSubmit}>
        <FormInput
          type="text"
          text="Table Name"
          name="tableName"
          placeholder="Table Name"
        />
        <Styled.ButtonsContainer>
          <Styled.Button type="submit">Submit</Styled.Button>
        </Styled.ButtonsContainer>
      </Styled.Form>
    </FormikProvider>
  );
};
