import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Modal from 'react-modal';
import { SideBar } from '../common/components/sidebar';
import { Menu } from './components/menu';
import { Styled } from './menu-page.styled';
import { CategoryModal } from './components/modal';
import { ROUTES } from '../common/constants';
import { menuService } from './services/menu.service';
import { ICategory, ICategoryPayload, ModalTypes } from './types';

interface IMenuPageParams {
  venueId: string;
}

export const MenuPage = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { venueId } = useParams<IMenuPageParams>();
  const [activeModalType, setActiveModalType] = useState(ModalTypes.None);
  const [selectedCategory, setSelectedCategory] = useState<ICategory>();
  const { data } = useQuery(
      ROUTES.ROUTES_MENU,
      () => menuService.getMenu(venueId),
  );
  const [menuData, setMenuData] = useState(data || []);

  useEffect(() => {
    setMenuData(data || []);
  }, [data]);

  const closeModal = () => {
    setActiveModalType(ModalTypes.None);
    setSelectedCategory(undefined);
  };

  const back = () => {
    history.push('/management');
  };

  const addCategoryMutation = useMutation(( { newCategory }:any) =>
    menuService.addMenu(newCategory, venueId), {
    onSuccess: () => {
      queryClient.invalidateQueries('menu');
    },
  });
  const onAddSubmit = async (newCategory: ICategoryPayload) => {
    addCategoryMutation.mutate({ newCategory });
  };

  const deleteCategoryMutation = useMutation(( { categoryId }:any) =>
    menuService.deleteMenu(categoryId), {
    onSuccess: () => {
      queryClient.invalidateQueries('menu');
    },
  });
  const onDelete = (categoryId: string) => {
    deleteCategoryMutation.mutate({ categoryId });
  };

  const editCategoryMutation = useMutation(( { id, newCategory }:any) =>
    menuService.editMenu(id, newCategory), {
    onSuccess: () => {
      queryClient.resetQueries('menu');
    },
  });
  const onEditSubmit = (id: string, newCategory: ICategoryPayload) => {
    editCategoryMutation.mutate({ id, newCategory });
  };

  const openCreateModal = () => setActiveModalType(ModalTypes.Create);

  const openUpdateModal = async (categoryId: string) => {
    setSelectedCategory(menuData.find(({ id }) => id === categoryId));
    setActiveModalType(ModalTypes.Edit);
  };

  return (
    <Styled.Wrapper>
      <SideBar />
      <Styled.ContentWrapper>
        <Styled.TopButtonsWrapper>
          <Styled.BackButton onClick={back} />
          <Styled.Button onClick={openCreateModal}>
            Add category
          </Styled.Button>
        </Styled.TopButtonsWrapper>
        <Menu
          data={menuData!}
          onDelete={onDelete}
          onEdit={openUpdateModal}
          onMenuItemEdit={onEditSubmit}
          venueId={venueId}
        />
        <Modal
          isOpen={activeModalType !== ModalTypes.None}
          onRequestClose={closeModal}
          ariaHideApp={false}
          // @ts-ignore
          style={Styled.modalStyles}
        >
          {(activeModalType !== ModalTypes.None) && (
            <CategoryModal
              onClose={closeModal}
              onSubmit={activeModalType === ModalTypes.Create ? onAddSubmit : onEditSubmit}
              data={selectedCategory}
            />
          )}
          <Styled.RoundButton onClick={closeModal}>X</Styled.RoundButton>
        </Modal>
      </Styled.ContentWrapper>
    </Styled.Wrapper>
  );
};
