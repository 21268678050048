import React, { FC, useState } from 'react';
import { IDishOption, IDishPayload } from '../../../types';
import { EditModalItemActive } from './edit-modal-item-active';
import { EditModalItemDisable } from './edit-modal-item-disable';


interface IEditModalItemProps{
  option: IDishOption | IDishPayload,
  onEdit: Function,
  onDelete: Function,
}

export const EditModalItem:FC<IEditModalItemProps> = ({ option, onEdit, onDelete }) => {
  const [isEditModeEnable, setIsEditModeEnable] = useState<boolean>(false);

  const enableEditMode = () => {
    setIsEditModeEnable(true);
  };

  const saveOption = (name:string, price:number) => {
    const optionPayload = { name, price };
    onEdit({ id: option.id, option: optionPayload });
    setIsEditModeEnable(false);
  };

  return (
    <>
      {isEditModeEnable ?
        <EditModalItemActive onDelete={onDelete} option={option} onSave={saveOption} /> :
        <EditModalItemDisable onDelete={onDelete} option={option}
          isEditModeEnable={isEditModeEnable} onEnableEditMode={enableEditMode} />}
    </>
  );
};


