import { MockDB } from '../modules/common/services/mock-http.service';
import { HttpMethods } from '../types';

export default function(baseURL: string) {
  MockDB.connection().addRecord(baseURL +'/table/venue', {
    [HttpMethods.GET]: {
      status: 200,
      data: [
        {
          id: '99117258-2842-4726-8c27-79a9c3a29fc4',
          name: 'Table1',
          nfcCode: {
            id: 'string',
            code: '99117258-2842-4726-8c27-79a9c3a29fc4',
          },
        },
        {
          id: '99117258-2842-4726-8c27-79a9c3a29fc7',
          name: 'Table2',
          nfcCode: {
            id: 'string',
            code: '99117258-2842-4726-8c27-79a9c3a29fc4',
          },
        },
      ],
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        test: 'post',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        test: 'put',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });

  MockDB.connection().addRecord(baseURL + '/table', {
    [HttpMethods.GET]: {
      status: 200,
      data: {
        test: 'get',
      },
    },
    [HttpMethods.POST]: {
      status: 200,
      data: [
        {
          id: 'string',
          name: 'string',
          nfcCode: {
            id: 'string',
            code: '99117258-2842-4726-8c27-79a9c3a29fc4',
          },
        },
      ],
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        tableName: 'Table 1',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        id: '91d70681-626c-4bac-9ac9-9a263fc1c268',
      },
    },
  });
}
