import React, { useState } from 'react';
import { SideBar } from '../common/components/sidebar';
import { StatisticTabs } from './components/statistic-tabs';
import { IDateRange, StatisticPeriods } from './types';
import { Styled } from './statistics.styled';
import { WaitersStatisticTable } from './components/waiters-statistic-table';
import { useHistory } from 'react-router';
import { DateRangePicker } from './components/date-picker-form';

export const WaitersStatisticsPage = () => {
  const [customStatisticPeriod, setCustomStatisticPeriod] = useState<IDateRange>({ from: '', to: '' });
  const [statisticPeriod, setStatisticPeriod] = useState<StatisticPeriods>(StatisticPeriods.TODAY);
  const history = useHistory();

  const back = () => {
    history.push('/statistics');
  };

  return (
    <Styled.Wrapper>
      <SideBar />
      <Styled.StatisticWrapper>
        <Styled.TopSection>
          <Styled.BackButton onClick={back} />
          <StatisticTabs
            statisticPeriod={statisticPeriod}
            setStatisticPeriod={setStatisticPeriod}
          />
          <DateRangePicker
            setStatisticPeriod={setStatisticPeriod}
            setCustomStatisticPeriod={setCustomStatisticPeriod}
          />
        </Styled.TopSection>
        <WaitersStatisticTable
          statisticPeriod={statisticPeriod}
          customStatisticPeriod={customStatisticPeriod}
        />
      </Styled.StatisticWrapper>
    </Styled.Wrapper>
  );
};
