import { FormikProvider, useFormik } from 'formik';
import React, { FC } from 'react';
import { FormInput } from '../../../common/components/form-input';
import { IDateRange, StatisticPeriods } from '../../types';
import { validationSchema } from '../../utils/validation-schema';
import { Styled } from './date-range-picker.styled';

interface IDateRangePickerProps {
  setStatisticPeriod: (statisticPeriod: StatisticPeriods) => void
  setCustomStatisticPeriod: (cusomStatisticPeriod: IDateRange) => void
}

export const DateRangePicker:FC<IDateRangePickerProps> = ({ setCustomStatisticPeriod, setStatisticPeriod }) => {
  const formik = useFormik({
    initialValues: {
      from: '',
      to: '',
    },
    onSubmit: ({ from, to }) => {
      const processedFrom = from ? new Date(from).toISOString() : null;
      const processedTo = to ? new Date(to).toISOString() : null;

      setCustomStatisticPeriod({
        from: processedFrom,
        to: processedTo,
      });

      setStatisticPeriod(StatisticPeriods.CUSTOM);
    },
    validationSchema: validationSchema,
  });

  return (
    <FormikProvider value={formik}>
      <Styled.Form onSubmit={formik.handleSubmit}>
        <FormInput
          name="from"
          type="datetime-local"
        />
        <FormInput
          name="to"
          type="datetime-local"
        />
        <Styled.Button
          type="submit"
        >Submit</Styled.Button>
      </Styled.Form>
    </FormikProvider>
  );
};
