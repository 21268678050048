import styled from 'styled-components';
import arrowLeft from '../../../../assets/arrow-left.png';

const Wrapper = styled.div`
  display: flex;
  min-width: 1024px;
  height: 100vh;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ theme }) => `${theme.spaces.xxl} ${theme.spaces.lg} ${theme.spaces.lg}`};
`;

const TopButtonWrapper = styled.div`
  justify-content: space-between;
  display: flex;
`;

const BackButton = styled.div`
  background-image: url(${arrowLeft});
  height: 40px;
  width: 40px;
  background-size: cover;
  margin: ${({ theme }) => `${theme.spaces.xs} 0 ${theme.spaces.md}`};
  cursor: pointer;
`;

const Form = styled.form`
  display: block;
  width: 450px;
  height: auto;
  color: ${({ theme }) => theme.colors.black};
`;

const FormFields = styled.div`
  display: flex;
`;

const BaseTitle = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fonts.xl};
  line-height: 28px;
  letter-spacing: -0.3px;
  margin: 0;
`;

const OptionsWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.sm};
`;
const OptionsList = styled.ul`
  margin-bottom: ${({ theme }) => theme.spaces.xs};
  padding-left: 0;
`;

const OptionsTitle = styled.h3`
  margin-bottom: ${({ theme }) => theme.spaces.xs};
`;

const Title = styled(BaseTitle)``;

const Subtitle = styled(BaseTitle)`
  font-size: 20px;
  margin-bottom: ${({ theme }) => theme.spaces.md};
`;

const HeaderWrapper = styled.div``;

const InputContainer = styled.div`
  margin-right: ${({ theme }) => theme.spaces.xxl};
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsContainer = styled.div``;

const ClearButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spaces.xs};
`;

const Preview = styled.img`
  width: 318px;
  height: 160px;
`;

export const Styled = {
  Wrapper,
  ContentWrapper,
  BackButton,
  TopButtonWrapper,
  InputContainer,
  ImageContainer,
  HeaderWrapper,
  ButtonsContainer,
  Form,
  OptionsTitle,
  OptionsList,
  OptionsWrapper,
  Title,
  Subtitle,
  Preview,
  FormFields,
  ClearButtonContainer,
};
