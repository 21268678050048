/* eslint-disable max-len */
import { MockDB } from '../modules/common/services/mock-http.service';
import { useDates } from '../modules/statistics/hooks/useDates';
import { HttpMethods } from '../types';

export default function(baseURL: string) {
  const { today } = useDates();
  MockDB.connection().addRecord(baseURL +'/statistics?orderStatus=completed&orderItem=delivered', {
    [HttpMethods.GET]: {
      status: 200,
      data: [
        {
          'venue_id': 'b7558a42-eaa2-40c7-bf23-32b135dd3105',
          'venue_createdAt': '2021-10-12T08:44:48.701Z',
          'venue_updatedAt': '2021-10-12T08:44:48.701Z',
          'venue_name': 'McDack 1',
          'venue_metadataId': '',
          'amount': 136.5,
        },
        {
          'venue_id': '94bb6109-c523-44cd-a1e1-9c159601d8fb',
          'venue_createdAt': '2021-10-14T13:26:42.697Z',
          'venue_updatedAt': '2021-10-14T13:26:42.697Z',
          'venue_name': 'Venue 1',
          'venue_metadataId': '',
          'amount': 1228.5,
        },
      ],
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        test: 'post',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        test: 'put',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });
  MockDB.connection().addRecord(baseURL +`/statistics?orderStatus=completed&orderItem=delivered&from=${today}`, {
    [HttpMethods.GET]: {
      status: 200,
      data: [
        {
          'venue_id': 'b7558a42-eaa2-40c7-bf23-32b135dd3105',
          'venue_createdAt': '2021-10-12T08:44:48.701Z',
          'venue_updatedAt': '2021-10-12T08:44:48.701Z',
          'venue_name': 'Today venue',
          'venue_metadataId': '',
          'amount': 136.5,
        },
        {
          'venue_id': '94bb6109-c523-44cd-a1e1-9c159601d8fb',
          'venue_createdAt': '2021-10-14T13:26:42.697Z',
          'venue_updatedAt': '2021-10-14T13:26:42.697Z',
          'venue_name': 'Venue 1',
          'venue_metadataId': '',
          'amount': 1228.5,
        },
      ],
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        test: 'post',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        test: 'put',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });
  MockDB.connection().addRecord(baseURL +'/statistics?selectObject=waiter&orderStatus=completed&orderItem=delivered&venueId=b7558a42-eaa2-40c7-bf23-32b135dd3105', {
    [HttpMethods.GET]: {
      status: 200,
      data: [
        {
          'waiter_id': 'b6bd8583-2cdc-4a63-bf62-03d9213887cf',
          'waiter_createdAt': '2021-10-14T13:39:26.414Z',
          'waiter_updatedAt': '2021-10-14T13:39:26.414Z',
          'waiter_email': 'ow1@gmail.com',
          'waiter_name': 'Mary 1',
          'waiter_venueId': '94bb6109-c523-44cd-a1e1-9c159601d8fb',
          'amount': 1365,
        },
        {
          'waiter_id': 'b6bd8583-2cdc-4a63-bf62-03d9213887cn',
          'waiter_createdAt': '2021-10-14T13:39:26.414Z',
          'waiter_updatedAt': '2021-10-14T13:39:26.414Z',
          'waiter_email': 'ow1@gmail.com',
          'waiter_name': 'Mary 2',
          'waiter_venueId': '94bb6109-c523-44cd-a1e1-9c159601d8fb',
          'amount': 2000,
        },
      ],
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        test: 'post',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        test: 'put',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });
  MockDB.connection().addRecord(baseURL +`/statistics?selectObject=waiter&orderStatus=completed&orderItem=delivered&from=${today}&venueId=b7558a42-eaa2-40c7-bf23-32b135dd3105`, {
    [HttpMethods.GET]: {
      status: 200,
      data: [
        {
          'waiter_id': 'b6bd8583-2cdc-4a63-bf62-03d9213887cf',
          'waiter_createdAt': '2021-10-14T13:39:26.414Z',
          'waiter_updatedAt': '2021-10-14T13:39:26.414Z',
          'waiter_email': 'ow1@gmail.com',
          'waiter_name': 'Mary 1 today',
          'waiter_venueId': '94bb6109-c523-44cd-a1e1-9c159601d8fb',
          'amount': 1365,
        },
        {
          'waiter_id': 'b6bd8583-2cdc-4a63-bf62-03d9213887cn',
          'waiter_createdAt': '2021-10-14T13:39:26.414Z',
          'waiter_updatedAt': '2021-10-14T13:39:26.414Z',
          'waiter_email': 'ow1@gmail.com',
          'waiter_name': 'Mary 2',
          'waiter_venueId': '94bb6109-c523-44cd-a1e1-9c159601d8fb',
          'amount': 2000,
        },
      ],
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        test: 'post',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        test: 'put',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });
  MockDB.connection().addRecord(baseURL +'/statistics?selectObject=table&orderStatus=completed&orderItem=delivered&venueId=b7558a42-eaa2-40c7-bf23-32b135dd3105', {
    [HttpMethods.GET]: {
      status: 200,
      data: [
        {
          'table_id': '1c500681-7b51-412a-991a-49b0f2ad15fc',
          'table_createdAt': '2021-10-12T08:44:48.726Z',
          'table_updatedAt': '2021-10-22T10:30:43.798Z',
          'table_name': 'super table',
          'table_nfcCodeId': '879f1a63-0956-4883-bdb9-1167eceb9146',
          'table_venueId': 'b7558a42-eaa2-40c7-bf23-32b135dd3105',
          'table_statusId': null,
          'amount': 200.5,
        },
        {
          'table_id': 'd7fb838a-2ea5-4f1b-9cc8-aaa55c662bbd',
          'table_createdAt': '2021-10-14T13:26:42.813Z',
          'table_updatedAt': '2021-10-14T13:26:42.844Z',
          'table_name': 'Table 1',
          'table_nfcCodeId': 'f8282c46-c07b-4a3d-a139-3c058226abc8',
          'table_venueId': '94bb6109-c523-44cd-a1e1-9c159601d8fb',
          'table_statusId': null,
          'amount': 1500.5,
        },
      ],
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        test: 'post',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        test: 'put',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });
  MockDB.connection().addRecord(baseURL +`/statistics?selectObject=table&orderStatus=completed&orderItem=delivered&from=${today}&venueId=b7558a42-eaa2-40c7-bf23-32b135dd3105`, {
    [HttpMethods.GET]: {
      status: 200,
      data: [
        {
          'table_id': '1c500681-7b51-412a-991a-49b0f2ad15fc',
          'table_createdAt': '2021-10-12T08:44:48.726Z',
          'table_updatedAt': '2021-10-22T10:30:43.798Z',
          'table_name': 'super table today',
          'table_nfcCodeId': '879f1a63-0956-4883-bdb9-1167eceb9146',
          'table_venueId': 'b7558a42-eaa2-40c7-bf23-32b135dd3105',
          'table_statusId': null,
          'amount': 200.5,
        },
        {
          'table_id': 'd7fb838a-2ea5-4f1b-9cc8-aaa55c662bbd',
          'table_createdAt': '2021-10-14T13:26:42.813Z',
          'table_updatedAt': '2021-10-14T13:26:42.844Z',
          'table_name': 'Table 1',
          'table_nfcCodeId': 'f8282c46-c07b-4a3d-a139-3c058226abc8',
          'table_venueId': '94bb6109-c523-44cd-a1e1-9c159601d8fb',
          'table_statusId': null,
          'amount': 1500.5,
        },
      ],
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        test: 'post',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        test: 'put',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });
  MockDB.connection().addRecord(baseURL +'/statistics?selectObject=order&orderStatus=deleted&venueId=b7558a42-eaa2-40c7-bf23-32b135dd3105', {
    [HttpMethods.GET]: {
      status: 200,
      data: [
        {
          'order_id': '00011ed2-289e-4a95-b021-88a88a4ea412',
          'order_createdAt': '2021-10-13T14:47:04.969Z',
          'order_updatedAt': '2021-10-26T15:44:28.100Z',
          'order_numberId': 777,
          'order_tableId': '5940aa68-2764-4f4c-9f72-c9485b6c9d05',
          'order_statusId': 'ca6b92a5-bf77-4f18-99e1-d75f1bed9aeb',
          'order_waiterId': 'dff4d5a1-5bf0-428c-a36c-3d402795ff27',
          'amount': 409.5,
        },
        {
          'order_id': 'ef4f2d55-df7f-4e68-8421-c3bc90643523',
          'order_createdAt': '2021-10-19T15:06:59.724Z',
          'order_updatedAt': '2021-10-20T09:19:47.519Z',
          'order_numberId': 566,
          'order_tableId': '1c500681-7b51-412a-991a-49b0f2ad15fc',
          'order_statusId': 'ca6b92a5-bf77-4f18-99e1-d75f1bed9aeb',
          'order_waiterId': 'b6bd8583-2cdc-4a63-bf62-03d9213887cf',
          'amount': 273,
        },
      ],
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        test: 'post',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        test: 'put',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });
  MockDB.connection().addRecord(baseURL +`/statistics?selectObject=order&orderStatus=deleted&from=${today}&venueId=b7558a42-eaa2-40c7-bf23-32b135dd3105`, {
    [HttpMethods.GET]: {
      status: 200,
      data: [
        {
          'order_id': '00011ed2-289e-4a95-b021-88a88a4ea412 today',
          'order_createdAt': '2021-10-13T14:47:04.969Z',
          'order_updatedAt': '2021-10-26T15:44:28.100Z',
          'order_numberId': 145,
          'order_tableId': '5940aa68-2764-4f4c-9f72-c9485b6c9d05',
          'order_statusId': 'ca6b92a5-bf77-4f18-99e1-d75f1bed9aeb',
          'order_waiterId': 'dff4d5a1-5bf0-428c-a36c-3d402795ff27',
          'amount': 409.5,
        },
        {
          'order_id': 'ef4f2d55-df7f-4e68-8421-c3bc90643523',
          'order_createdAt': '2021-10-19T15:06:59.724Z',
          'order_updatedAt': '2021-10-20T09:19:47.519Z',
          'order_numberId': 1,
          'order_tableId': '1c500681-7b51-412a-991a-49b0f2ad15fc',
          'order_statusId': 'ca6b92a5-bf77-4f18-99e1-d75f1bed9aeb',
          'order_waiterId': 'b6bd8583-2cdc-4a63-bf62-03d9213887cf',
          'amount': 273,
        },
      ],
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        test: 'post',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        test: 'put',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });
}
