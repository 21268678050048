import styled from 'styled-components';
import { FormInput } from '../../../common/components/form-input';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  width: 300px;
  height: auto;
  color: ${({ theme }) => theme.colors.black};
  justify-content: center;
`;

const Input = styled.input`
  box-sizing: border-box;
  display: block;
  width: 300px;
  margin: 0 auto;
  height: 25px;
  padding: 5px;
  font-size: 18px;
  border: none;
  outline: none;
  border: 1px solid;
  margin-bottom: 20px;
  border-color: ${({ theme }) => theme.colors.not_active};
  &:focus {
    border-color: ${({ theme }) => theme.colors.focused};
    box-shadow: 0 0 10px rgba(0, 191, 255,0.5);
  }
`;

const Text = styled.h1`
  width: 100%;
  display: block;
  margin: 0 auto;
  text-align: center;
  margin: 15px;
  font-size: 24px
`;

const Button = styled.button`
  border: 2px solid;
  border-color: ${({ theme }) => theme.colors.white};
  border-radius: 7px;
  width: 100%;
  height: 45px;
  margin: 20px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
`;

const ErrorBox = styled.div`
 width: 200px;
 margin: 0 auto;
 margin-top: 10px;
 margin-bottom: 10px;
`;

const NewFormInput = styled(FormInput)`
  border: none;
  border-radius: 0px;
  margin-left: 500px;
`;

export const Styled = {
  Form,
  Input,
  Text,
  Button,
  ErrorBox,
  NewFormInput,
};
