import { ROUTES } from '../../common/constants/index';

/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpService } from '../../common/services/http.service';
import { HttpServiceFactory } from '../../common/services/index';
import { IResponse } from '../../../types';
import { IOwner, IVenue } from '../types';
import { EnhancedWithAuthHttpService } from '../../common/services/http-auth.service';

export class VenueService {
  private httpService: EnhancedWithAuthHttpService

  constructor(private httpFactory: HttpServiceFactory) {
    this.httpService = this.httpFactory.createAuthHttpService();
  }

  public getVenues(): Promise<IVenue[] | void> {
    return this.httpService.get<IVenue[]>(ROUTES.ROUTES_VENUES);
  }

  public createVenue(venue: IVenue): Promise<IVenue | void> {
    return this.httpService.post(ROUTES.ROUTES_VENUES, venue);
  }

  public updateVenue(venue: IVenue)
  : Promise<IVenue | void> {
    return this.httpService.put(`${ROUTES.ROUTES_VENUES}/${venue.id}`, venue );
  }

  public deleteVenue(id: string): Promise<IResponse<IVenue> | void> {
    return this.httpService.delete(`${ROUTES.ROUTES_VENUES}/${id}`);
  }
}

export const venueService = new VenueService(new HttpServiceFactory());
