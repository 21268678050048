import React from 'react';
import { useParams } from 'react-router-dom';
import { SideBar } from '../common/components/sidebar';
import { TableManagement } from './components/venue-management/table-management.component';
import { IVenueRouterParams } from './types';
import { Styled } from './venue-page.styled';

export const SelectedVenuePage = () => {
  const { venueId } = useParams<IVenueRouterParams>();
  return (
    <Styled.Wrapper>
      <SideBar />
      <TableManagement venueId={venueId} />
    </Styled.Wrapper>
  );
};
