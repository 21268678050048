/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Styled } from './modal.styled';
import { validationSchema } from '../../utils/category-validation-schema';
import { FormInput } from '../../../common/components/form-input';
import { ICategory, ICategoryPayload } from '../../types';
import { ImageDropzone } from '../../../common/components/dropzone';

interface ICategoryModalProps {
  onClose: Function;
  onSubmit: Function;
  data?: ICategory;
}

export const CategoryModal: React.FC<ICategoryModalProps> = ({
  onClose,
  onSubmit,
  data,
}) => {
  const [loadedIcon, setLoadedIcon] = useState(data?.media || '');
  const formik = useFormik({
    initialValues: {
      type: data?.type || '',
      media: data?.media || '',
    },
    onSubmit: ({ type, media }: ICategoryPayload) => {
      const newCategory: ICategoryPayload = {
        type: type,
        media,
      };

      onSubmit(newCategory, data?.id);
      onClose();
    },
    validationSchema,
  });

  useEffect(() => {
    setLoadedIcon(data?.media || '');
  }, [data]);

  useEffect(() => {
    formik.setFieldValue('media', loadedIcon);
  }, [loadedIcon]);

  const setFormikMedia = () => formik.setFieldValue('media', loadedIcon);
  const clearImage = () => setLoadedIcon('');

  return (
    <FormikProvider value={formik}>
      <Styled.Form onSubmit={formik.handleSubmit}>
        <Styled.SubTitle>{data ? 'Edit' : 'Add'} category</Styled.SubTitle>
        <FormInput
          type="text"
          text=""
          name="type"
          placeholder="Category name"
          width="318px"
        />
        {loadedIcon ? (
          <Styled.Preview src={loadedIcon} />
        ) : (
          <ImageDropzone setFormikMedia={setFormikMedia} setFileData={setLoadedIcon} />
        )}
        <Styled.ButtonsContainer>
          <Styled.Button type="submit">Submit</Styled.Button>
          <Styled.Button type="button" onClick={clearImage}>Clear image</Styled.Button>
        </Styled.ButtonsContainer>
      </Styled.Form>
    </FormikProvider>
  );
};
