import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Textarea = styled.textarea<{ width?: string, isErrorExists?: boolean }>`
  font-family: 'Roboto'; 
  box-sizing: border-box;
  display: block;
  width: ${({ width }) => width || '300px'};
  min-height: 110px;
  margin: 0;
  font-size: 18px;
  border: none;
  outline: none;
  border: 1px solid;
  margin-bottom: ${({ isErrorExists, theme }) => isErrorExists ? '0' : theme.spaces.sm};
  border-color: ${({ theme }) => theme.colors.not_active};
  &:focus {
    border-color: ${({ theme }) => theme.colors.focused};
    box-shadow: 0 0 10px rgba(0, 191, 255,0.5);
  }
`;

const Label = styled.label`
  display: block;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 15px;
  font-size: 24px;
`;

const ErrorBox = styled.div<{ width?: string}>`
  width: ${({ width }) => width || '300px'};
  text-align: left;
  padding-left: ${({ theme }) => theme.spaces.xxs};;
  margin-bottom: ${({ theme }) => theme.spaces.xs};
  margin-top: ${({ theme }) => theme.spaces.xs};
`;

export const Styled = {
  Textarea,
  Label,
  ErrorBox,
  Wrapper,
};
