import React from 'react';
import { LoginForm } from './components/login-form';
import { Wrapper } from './login.styled';

export const Login = () => {
  return (
    <Wrapper>
      <LoginForm/>
    </Wrapper>
  );
};
