import React from 'react';
import { useParams } from 'react-router';
import { StyledTable } from '../../../common/components/table';
import { ITableColumn, TableCellType } from '../../../common/types';
import { useQueryDataByPeriod } from '../../hooks/useQueryDataByPeriod';
import { statisticService } from '../../services/statistic.service';
import { IDateRange, IStatisticParams, IVenueStatistic } from '../../types';
import { StatisticPeriods } from '../../types';
import { converseAmountToFixed } from '../../utils/amount-to-fixed';
import { sortArray } from '../../utils/sortArray';


interface IVenueTableProps {
  statisticPeriod: StatisticPeriods,
  customStatisticPeriod: IDateRange,
  additionalColumns?: ITableColumn[];
}


export const WaitersStatisticTable: React.FC<IVenueTableProps> = ({
  statisticPeriod,
  customStatisticPeriod,
  additionalColumns = [],
}) => {
  const { venueId } = useParams<IStatisticParams>();

  const { data } = useQueryDataByPeriod({
    name: 'waiters-statistic',
    venueId,
    statisticPeriod,
    customStatisticPeriod,
    fetchMethod: statisticService.getWaitersStatistic.bind(statisticService),
  });

  const columns: ITableColumn[] = [
    { title: 'Name',
      key: 'waiter_name',
      type: TableCellType.Text,
    },
    {
      title: 'Total Income',
      key: 'amount',
      type: TableCellType.Text,
    },
    ...additionalColumns,
  ];
  const sortedData = sortArray(converseAmountToFixed(data) as IVenueStatistic[], 'waiter_id', 'ASC');

  return (
    <StyledTable
      columns={columns}
      rows={sortedData || []}
      keyProp="waiter_id"
    ></StyledTable>
  );
};

