import { FormInput } from '../../common/components/form-input';
import { Textarea } from '../../common/components/textarea';

export const UPDATE_INPUTS = [
  {
    type: 'text',
    text: 'Venue Name',
    name: 'venueName',
    placeholder: 'Venue name',
    Component: FormInput,
  },
  {
    type: 'text',
    text: 'Venue address',
    name: 'address',
    placeholder: 'Venue address',
    Component: FormInput,
  },
  {
    type: 'text',
    text: 'Open Hours',
    name: 'openHours',
    placeholder: 'Open Hours',
    Component: FormInput,
  },
  {
    type: 'text',
    text: 'Venue description',
    name: 'description',
    placeholder: 'Venue description',
    Component: Textarea,
  },
  {
    type: 'text',
    text: 'Owner Name',
    name: 'venueOwnerName',
    placeholder: 'Owner Name',
    Component: FormInput,
  },
  {
    type: 'email',
    text: 'Owner Email Address',
    name: 'ownerEmail',
    placeholder: 'Owner Email Address',
    Component: FormInput,
  },
  {
    type: 'password',
    text: 'Password',
    name: 'password',
    placeholder: 'Password',
    Component: FormInput,
  },
  {
    type: 'checkbox',
    text: 'Accept Orders',
    name: 'acceptOrders',
    Component: FormInput,
  },
  {
    type: 'checkbox',
    text: 'Call Waiter',
    name: 'callWaiter',
    Component: FormInput
  },
];

export const CREATE_INPUTS = [
  {
    type: 'text',
    text: 'Venue Name',
    name: 'venueName',
    placeholder: 'Venue name',
    Component: FormInput,
  },
  {
    type: 'text',
    text: 'Venue address',
    name: 'address',
    placeholder: 'Venue address',
    Component: FormInput,
  },
  {
    type: 'text',
    text: 'Open Hours',
    name: 'openHours',
    placeholder: 'Open Hours',
    Component: FormInput,
  },
  {
    type: 'text',
    text: 'Venue description',
    name: 'description',
    placeholder: 'Venue description',
    Component: Textarea,
  },
  {
    type: 'text',
    text: 'Owner Name',
    name: 'venueOwnerName',
    placeholder: 'Owner Name',
    Component: FormInput,
  },
  {
    type: 'email',
    text: 'Owner Email Address',
    name: 'ownerEmail',
    placeholder: 'Owner Email Address',
    Component: FormInput,
  },
  {
    type: 'number',
    text: 'Number of tables',
    name: 'countTables',
    placeholder: 'Number of Tables',
    Component: FormInput,
    min: 1,
  },
  {
    type: 'password',
    text: 'Password',
    name: 'password',
    placeholder: 'Password',
    Component: FormInput,
  },
  {
    type: 'checkbox',
    text: 'Accept Orders',
    name: 'acceptOrders',
    Component: FormInput,
  },
  {
    type: 'checkbox',
    text: 'Call Waiter',
    name: 'callWaiter',
    Component: FormInput,
  },
];
