import { ILoginResponse } from '../types/index';
import { HttpService } from '../../common/services/http.service';
import { HttpServiceFactory } from '../../common/services/index';

export class UserService {
  constructor(private httpService: HttpService) {}
  public loginUser(email: string, password: string): Promise<ILoginResponse | void> {
    return this.httpService.post('admin-auth/login', { email, password });
  }
};

const factory = new HttpServiceFactory();   
export const authService = new UserService(factory.createHttpService());
