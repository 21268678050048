import React from 'react';
import { Styled } from './sidebar.styled';

export const SideBar = () => {
  return (
    <Styled.SideBarSection>
      <Styled.Title>Pages</Styled.Title>
      <Styled.Link to="/management">
          Venue Management
      </Styled.Link>
      <Styled.Link to="/statistics">
          Statistics
      </Styled.Link>
    </Styled.SideBarSection>);
};
