export const colorPalette = {
  white: '#ffffff',
  black: '#1e2939',
  primary: '#bbb2e9',
  secondary: '#808080',
  not_active: '#d3d3d3',
  focused: '#00bfff',
  primary_grey: '#C4C4C4',
  secondary_grey: '#E5E5E5',
  green: '#3BA707',
  red: '#F22100',
};
