import { MockDB } from '../modules/common/services/mock-http.service';
import { HttpMethods } from '../types';

export default function() {
  MockDB.connection().addRecord('https://httpbin.org/api/test', {
    [HttpMethods.GET]: {
      status: 200,
      data: {
        test: 'get',
      },
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        test: 'post',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        test: 'put',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });
}
