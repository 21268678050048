import styled from 'styled-components';
import deleteIcon from '../../../../assets/garbage.png';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 450px;
  height: auto;
  color: ${({ theme }) => theme.colors.black};
  padding: 50px;
`;

const Button = styled.button`
  border: 2px solid;
  border-color: ${({ theme }) => theme.colors.white};
  border-radius: 7px;
  min-width: 150px;
  max-width: 200px;
  height: 45px;
  margin: 20px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
`;

const DeleteButton = styled.div`
  background-image: url(${deleteIcon});
  height: 30px;
  width: 30px;
  background-size: cover;
  margin: auto;
  cursor: pointer;
  margin-left: 100px;
`;

const ButtonsContainer = styled.div`
  display: flex;
`;

export const Styled = {
  Form,
  Button,
  DeleteButton,
  ButtonsContainer,
};
