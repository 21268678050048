import styled from 'styled-components';
import DeleteIcon from '../../../../../assets/garbage.png';

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spaces.xs } 0;
`;

const Name = styled.div`
  flex-basis: 40%;
  width: "35%";
  word-break: break-all;
`;
const Price = styled.div`
  flex-basis: 40%;
`;
const Edit = styled.div`
  flex-basis: 10%;
  padding: ${({ theme }) => theme.spaces.xxs} 5px;
`;
const Delete = styled.button`
  background-color: transparent;
  border: none;
  background-image: url(${DeleteIcon});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  cursor: pointer;
`;

const Input = styled.input`
  height: 20px;
  padding: ${({ theme }) => theme.spaces.sm} 5px;
  font-size: 16px;
  border: none;
  outline: none;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.not_active};
  &:focus {
    border-color: ${({ theme }) => theme.colors.focused};
    box-shadow: 0 0 10px rgba(0, 191, 255,0.5);
  }
`;

export const Styled = {
  ItemWrapper,
  Name,
  Price,
  Edit,
  Delete,
  Input,
};
