import { MockDB } from '../modules/common/services/mock-http.service';
import { HttpMethods } from '../types';

export default function(baseURL: string) {
  MockDB.connection().addRecord(baseURL + '/admin-auth/login', {
    [HttpMethods.GET]: {
      status: 200,
      data: {
        token: 'eyJhbGciOiJ',
      },
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        token: 'eyJhbGciOiJ',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        test: 'put',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });
}
