interface IWithAmount{
  amount: number | string;
}

export const converseAmountToFixed = (data: IWithAmount[]) => {
  return data?.map((item: IWithAmount) => {
    item.amount = Number(item?.amount)?.toFixed(2);
    return item;
  });
};
