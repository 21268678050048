import { useDates } from '../hooks/useDates';
import { IDatesForStatistic, StatisticPeriods } from '../types';

export const useDatesForStatistic = (): IDatesForStatistic => {
  const { yesterdayFrom, yesterdayTo, weekFrom, weekTo, monthFrom, monthTo, today } = useDates();

  const datesForStatistic = {
    [StatisticPeriods.ALL]: {
      'from': null,
      'to': null,
    },
    [StatisticPeriods.LAST_DAY]: {
      'from': yesterdayFrom,
      'to': yesterdayTo,
    },
    [StatisticPeriods.LAST_WEEK]: {
      'from': weekFrom,
      'to': weekTo,
    },
    [StatisticPeriods.LAST_30_DAYS]: {
      'from': monthFrom,
      'to': monthTo,
    },
    [StatisticPeriods.TODAY]: {
      'from': today,
      'to': null,
    },
    [StatisticPeriods.CUSTOM]: {
      'from': null,
      'to': null,
    },
  };
  return datesForStatistic;
};
