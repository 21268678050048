import { MockDB } from '../modules/common/services/mock-http.service';
import { HttpMethods } from '../types';
import Pasta from '../assets/pasta.png';

export default function(baseURL: string) {
  MockDB.connection().addRecord(baseURL + '/venue', {
    [HttpMethods.GET]: {
      status: 200,
      data: [
        {
          'id': '881828e3-c858-48f8-a305-d478dc9c88ef',
          'createdAt': '2021-10-28T12:02:42.082Z',
          'updatedAt': '2021-10-28T12:02:42.082Z',
          'name': 'V 1',
          'metadataId': '',
          'media': Pasta,
          'venueOwner': {
            'id': '4ed6832c-5c54-425d-a45b-5c89d7f6e632',
            'createdAt': '2021-10-28T12:02:42.434Z',
            'updatedAt': '2021-10-28T12:02:42.434Z',
            'email': 'ow212s322s@gmail.com',
            'name': '23ss',
            'metadataId': '',
          },
          'tablesCount': {
            'venueId': '881828e3-c858-48f8-a305-d478dc9c88ef',
            'tables': '5',
          },
          'menus': [],
        },
        {
          'id': '881828e3-c858-48f8-a305-d478dc9c88eg',
          'createdAt': '2021-10-28T12:02:42.082Z',
          'updatedAt': '2021-10-28T12:02:42.082Z',
          'name': 'V 2',
          'metadataId': '',
          'media': '',
          'venueOwner': {
            'id': '4ed6832c-5c54-425d-a45b-5c89d7f6e632',
            'createdAt': '2021-10-28T12:02:42.434Z',
            'updatedAt': '2021-10-28T12:02:42.434Z',
            'email': 'ow212s322s@gmail.com',
            'name': '23ss',
            'metadataId': '',
          },
          'tablesCount': {
            'venueId': '881828e3-c858-48f8-a305-d478dc9c88eh',
            'tables': '5',
          },
          'menus': [],
        },
        {
          'id': '881828e3-c858-48f8-a305-d478dc9c88el',
          'createdAt': '2021-10-28T12:02:42.082Z',
          'updatedAt': '2021-10-28T12:02:42.082Z',
          'name': 'V 3',
          'metadataId': '',
          'media': '',
          'venueOwner': {
            'id': '4ed6832c-5c54-425d-a45b-5c89d7f6e632',
            'createdAt': '2021-10-28T12:02:42.434Z',
            'updatedAt': '2021-10-28T12:02:42.434Z',
            'email': 'ow212s322s@gmail.com',
            'name': '23ss',
            'metadataId': '',
          },
          'tablesCount': {
            'venueId': '881828e3-c858-48f8-a305-d478dc9c88ef',
            'tables': '5',
          },
          'menus': [],
        },
        {
          'id': '881828e3-c858-48f8-a305-d478dc9c88em',
          'createdAt': '2021-10-28T12:02:42.082Z',
          'updatedAt': '2021-10-28T12:02:42.082Z',
          'name': 'V 4',
          'metadataId': '',
          'media': '',
          'venueOwner': {
            'id': '4ed6832c-5c54-425d-a45b-5c89d7f6e632',
            'createdAt': '2021-10-28T12:02:42.434Z',
            'updatedAt': '2021-10-28T12:02:42.434Z',
            'email': 'ow212s322s@gmail.com',
            'name': '23ss',
            'metadataId': '',
          },
          'tablesCount': {
            'venueId': '881828e3-c858-48f8-a305-d478dc9c88ef',
            'tables': '5',
          },
          'menus': [],
        },
      ],
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        name: 'McDack',
        metadataid: '???',
        venueowneremail: 'McDack@gmail.com',
        venueownername: 'McDack',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        name: 'McDack',
        email: 'philip@gmail.com',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        id: '91d70681-626c-4bac-9ac9-9a263fc1c268',
      },
    },
  });
  MockDB.connection().addRecord(baseURL + '/venue/aff7ebed-6fd3-4a18-ae67-8a3ecc15834e', {
    [HttpMethods.GET]: {
      status: 200,
      data: {
        'id': '881828e3-c858-48f8-a305-d478dc9c88ef',
        'createdAt': '2021-10-28T12:02:42.082Z',
        'updatedAt': '2021-10-28T12:02:42.082Z',
        'name': 'V    1',
        'metadataId': '',
        'media': '',
        'venueOwner': {
          'id': '4ed6832c-5c54-425d-a45b-5c89d7f6e632',
          'createdAt': '2021-10-28T12:02:42.434Z',
          'updatedAt': '2021-10-28T12:02:42.434Z',
          'email': 'ow212s322s@gmail.com',
          'name': '23ss',
          'metadataId': '',
        },
        'tablesCount': {
          'venueId': '881828e3-c858-48f8-a305-d478dc9c88ef',
          'tables': '5',
        },
        'menus': [],
      },
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        name: 'McDack',
        metadataid: '???',
        venueowneremail: 'McDack@gmail.com',
        venueownername: 'McDack',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        name: 'McDack',
        email: 'philip@gmail.com',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        id: '91d70681-626c-4bac-9ac9-9a263fc1c268',
      },
    },
  });
}
