import React from 'react';
import {
  TableCell,
  TableRow,
} from '@material-ui/core';
import {
  ButtonTableCell,
  Button,
} from '../table/table.styled';
import { findProp } from '../../utils/find-props';
import { IMap, ITableColumn, TableCellType } from '../../types';

export interface IStyledTableRowProps {
  columns: ITableColumn[];
  item: IMap,
  keyProp: string,
};

export const StyledTableRow: React.FC<IStyledTableRowProps> = ({ columns, item, keyProp = 'id' }) => {
  return (
    <TableRow>
      {columns.map((column, key) =>
        (column.type === TableCellType.Text) ? (
          <TableCell key={`${item[keyProp]}_${column.key}`}>
            {findProp(item!, column.key)}</TableCell>
        ) : (
          <ButtonTableCell key={key}>
            <Button
              onClick={(event) => {
                if (column.onClick) {
                  column.onClick(item[keyProp], event);
                }
              }}
            >
              {column.title}
            </Button>
          </ButtonTableCell>
        ),
      )}
    </TableRow>
  );
};
