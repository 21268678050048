import { FormikProvider, useFormik } from 'formik';
import React, { FC } from 'react';
import { Styled } from './edit-modal-item.styled';
import { Button } from '../../../../common/components/button';
import { IDishOption, IDishPayload } from '../../../types';
import { optionValidationSchema } from '../../../utils/option-validation';
import { FormInput } from '../../../../common/components/form-input';


interface IEditModalItemActiveProps {
  option: IDishOption | IDishPayload;
  onSave: Function;
  onDelete: Function;
}

export const EditModalItemActive: FC<IEditModalItemActiveProps> = ({ option, onSave, onDelete }) => {
  const formik = useFormik({
    initialValues: {
      optionName: option.name,
      optionPrice: option.price,
    },
    validationSchema: optionValidationSchema,
    onSubmit: ({ optionName, optionPrice }) => {
      onSave(optionName, optionPrice);
    },
  });

  const onDeleteHandler = () => {
    onDelete({ id: option.id });
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Styled.ItemWrapper>
          <Styled.Name>
            <FormInput type="text" name="optionName" isEdit={true} maxLength={100} />
          </Styled.Name>
          <Styled.Price>
            <FormInput
              type="number"
              name="optionPrice"
              min={0.01}
              step={0.01}
              isEdit={true}
            />
          </Styled.Price>
          <Styled.Edit>
            <Button type="submit" text="Save" width="70px" height="34px" />
          </Styled.Edit>
          <Styled.Delete onClick={onDeleteHandler}></Styled.Delete>
        </Styled.ItemWrapper>
      </form>
    </FormikProvider>
  );
};
