/* eslint-disable react/react-in-jsx-scope */
import { FormikProvider, useFormik } from 'formik';
import { Styled } from './table-modal.styled';
import { validationSchema } from '../../utils/table-validation-schema';
import { FormInput } from '../../../common/components/form-input';
import { ITableFormValues, ITableObject } from '../../types';

export interface IUpdateTableModalProps {
  toCloseModal: Function;
  onSubmit: Function;
  onDelete: Function;
  table: ITableObject;
}

export const UpdateTableModal: React.FC<IUpdateTableModalProps> = ({
  toCloseModal,
  onSubmit,
  onDelete,
  table,
}) => {
  const formik = useFormik({
    initialValues: {
      tableName: table.name,
    },
    onSubmit: ({ tableName }: ITableFormValues) => {
      const newTable: ITableObject = {
        name: tableName,
        id: table.id,
      };

      onSubmit(newTable);
      toCloseModal();
    },
    validationSchema: validationSchema,
  });

  const deleteTable = () => {
    if (table && table.id) {
      onDelete(table?.id);
      toCloseModal();
    }
  };

  return (
    <FormikProvider value={formik}>
      <Styled.Form onSubmit={formik.handleSubmit}>
        <FormInput
          type="text"
          text="Table Name"
          name="tableName"
          placeholder="Table Name"
        />
        <Styled.ButtonsContainer>
          <Styled.Button type="submit">Submit</Styled.Button>
          <Styled.DeleteButton onClick={deleteTable}></Styled.DeleteButton>
        </Styled.ButtonsContainer>
      </Styled.Form>
    </FormikProvider>
  );
};
