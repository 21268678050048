// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { ITokenDecode } from '../../../types';
import { StoreKeys } from '../constants';

export class AuthService {
  constructor() {};
  public isAuthorized():boolean {
    return this.checkIsTokenValid();
  }

  public checkIsTokenValid() {
    const token = localStorage.getItem(StoreKeys.Token);
    if (!token) {
      return false;
    }

    if (token && !token?.includes('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9')) {
      this.logout();
      return false;
    }

    const tokenDecode = jwt_decode<ITokenDecode>(token as string);
    if (tokenDecode.exp * 1000 > Date.now()) {
      return true;
    } else {
      this.logout();
      return false;
    }
  }

  public logout():void {
    localStorage.removeItem(StoreKeys.Token);
    window.location.href = '/login';
  };

  public setLocalItem = (key: string, data: string) => {
    localStorage.setItem(key, data);
  };

  public getLocalItem = (key: string) => {
    return localStorage.getItem(key);
  };
};

export const auth = new AuthService();
