import styled from 'styled-components';

const Title = styled.h1`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fonts.xl};
  line-height: 28px;
  letter-spacing: -0.3px;
  margin: 0;

  color: ${({ theme }) => theme.colors.black};
`;

const HomeWrapper = styled.div`
  padding: ${({ theme }) => `${theme.spaces.md} ${theme.spaces.xl}`};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const AccordionWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.sm};
`;

export const Styled = {
  Title,
  ButtonWrapper,
  HomeWrapper,
  AccordionWrapper,
};
