import React, { useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { Styled } from './venue-management.styled';
import { TablesTable } from '../tables-table';
import { tableService } from '../../services/table.service';
import { UpdateTableModal } from '../table-modal/update-table-modal.component';
import { CreateTableModal } from '../table-modal/create-table-modal.component';
import { ITable, ITableObject, IVenueRouterParams } from '../../types';
import { useMutation, useQueryClient } from 'react-query';

export const TableManagement: React.FC<IVenueRouterParams> = ({ venueId }) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [updateTable, setUpdateTable] = useState<ITableObject | undefined>();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setUpdateTable(undefined);
  };

  const back = () => {
    history.push('/management');
  };

  const onUpdateTable = (id: string) => {
    const tables = queryClient.getQueryData<ITable[]>('table');
    const table = tables!.filter((table) => table.id === id)[0];
    setUpdateTable({ id: table.id, name: table.name });
    setIsOpen(true);
  };

  const updateTableMutation = useMutation(( { table }:any) =>
    tableService.updateTable(table.id, table.name), {
    onSuccess: () => {
      queryClient.invalidateQueries('table');
    },
  });

  const createTableMutation = useMutation(( { name }:any) =>
    tableService.createTable(venueId, name), {
    onSuccess: () => {
      queryClient.invalidateQueries('table');
    },
  });
  const deleteTableMutation = useMutation(( { id }:any) =>
    tableService.deleteTable(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('table');
    },
  });

  const onSubmitTableUpdate = (table:ITable) => {
    if (table.id) {
      updateTableMutation.mutate({ table });
      setUpdateTable(undefined);
    }
  };

  const onSubmitTableCreate = (name: string) => {
    createTableMutation.mutate({ name });
  };

  const onDeleteTable = async (id: string) => {
    deleteTableMutation.mutate({ id });
  };

  return (
    <Styled.Wrapper>
      <Styled.TopButtonsWrapper>
        <Styled.BackButton onClick={back} />
        <Styled.Button onClick={openModal}>
          Add Tables
        </Styled.Button>
      </Styled.TopButtonsWrapper>
      <Styled.ListWrapper>
        <TablesTable venueId={venueId} onUpdateTable={onUpdateTable} />
      </Styled.ListWrapper>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        // @ts-ignore
        style={Styled.modalStyles}
      >
        <Styled.RoundButton onClick={closeModal}>X</Styled.RoundButton>
        {updateTable ? (
            <UpdateTableModal
              toCloseModal={closeModal}
              onSubmit={onSubmitTableUpdate}
              onDelete={onDeleteTable}
              table={updateTable}
            />
          ) : (
            <CreateTableModal
              toCloseModal={closeModal}
              onSubmit={onSubmitTableCreate}
            />
          )}
      </Modal>
    </Styled.Wrapper>
  );
};
