import { useQuery } from 'react-query';
import { IDateRange, StatisticPeriods } from '../types';
import { useDatesForStatistic } from './useDatesForStatistic';

interface IQueryDataByPeriod{
  name: string,
  venueId: string | null,
  statisticPeriod: StatisticPeriods,
  customStatisticPeriod: IDateRange,
  fetchMethod: Function
}

export const useQueryDataByPeriod = ({
  name,
  venueId,
  statisticPeriod,
  customStatisticPeriod,
  fetchMethod }: IQueryDataByPeriod) => {
  const datesForStatistic = useDatesForStatistic();
  const queryPeriod = statisticPeriod === StatisticPeriods.CUSTOM ?
  customStatisticPeriod : datesForStatistic[statisticPeriod];

  const fetchFunction = (queryPeriod: IDateRange, venueId: string | null) => {
    return fetchMethod(queryPeriod, venueId);
  };
  return useQuery([name, venueId, statisticPeriod, queryPeriod], () => fetchFunction(queryPeriod, venueId));
};
