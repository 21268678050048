import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import { Styled } from './venue-management.styled';
import { VenueTable } from '../venue-table';
import { venueService } from '../../services/venue.service';
import { UpdateVenueModal } from '../venue-modal/update-venue.component';
import { CreateVenueModal } from '../venue-modal/create-venue.component';
import { IVenue } from '../../types';
import { TableCellType } from '../../../common/types';
import { useMutation, useQueryClient } from 'react-query';

export const VenueManagement = () => {
  const queryClient = useQueryClient();

  const history = useHistory();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [updateVenue, setUpdateVenue] = useState<IVenue | undefined>();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setUpdateVenue(undefined);
  };

  const onUpdateVenue = (id: string) => {
    const venues = queryClient.getQueryData<IVenue[]>('venue');
    const venue = venues?.filter((venue) => venue.id === id)[0];

    setUpdateVenue({
      id: venue?.id,
      name: venue?.name,
      description: venue?.description,
      address: venue?.address,
      openHours: venue?.openHours,
      venueOwner: {
        name: venue?.venueOwner?.name,
        email: venue?.venueOwner?.email,
        password: '',
      },
      media: venue?.media as string,
      callWaiter:venue?.callWaiter,
      acceptOrders:venue?.acceptOrders
    } as IVenue);
    setIsOpen(true);
  };

  const onTablesTapped = (id: string) => {
    history.push(`/management/${id}`);
  };

  const onShowMenu = (id: string) => {
    history.push(`/management/${id}/menu`);
  };

  const updateVenueMutation = useMutation(({ venue }:any) =>
    venueService.updateVenue(venue), {
    onSuccess: () => {
      queryClient.invalidateQueries('venue');
    },
  });
  const onSubmitVenueUpdate = async (venue: IVenue) => {
    if (venue.id) {
      updateVenueMutation.mutate({ venue });
      setUpdateVenue(undefined);
    }
  };

  const createVenueMutation = useMutation(( { venue }:any) =>
    venueService.createVenue(venue), {
    onSuccess: () => {
      queryClient.invalidateQueries('venue');
    },
  });
  const onSubmitVenueCreate = async (venue: IVenue) => {
    createVenueMutation.mutate({ venue });
  };

  const deleteVenueMutation = useMutation(( { id }:any) =>
    venueService.deleteVenue(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('venue');
    },
  });

  const onDeleteVenue = (id: string) => {
    deleteVenueMutation.mutate({ id });
  };

  const additionalVenueTableCols = [{
    title: 'Update',
    key: 'update',
    type: TableCellType.Button,
    onClick: onUpdateVenue,
  },
  {
    title: 'Tables',
    key: 'tables',
    type: TableCellType.Button,
    onClick: onTablesTapped,
  },
  {
    title: 'Show Menu',
    key: 'menu',
    type: TableCellType.Button,
    onClick: onShowMenu,
  }];

  return (
    <Styled.Wrapper>
      <Styled.TopButtonsWrapper>
        <Styled.Button onClick={openModal}>
          Add Venue
        </Styled.Button>
      </Styled.TopButtonsWrapper>
      <Styled.ListWrapper>
        <VenueTable
          additionalColumns={additionalVenueTableCols}
        />
      </Styled.ListWrapper>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        // @ts-ignore
        style={Styled.modalStyles}
      >
        <Styled.RoundButton onClick={closeModal}>X</Styled.RoundButton>
        {updateVenue ? (
          <UpdateVenueModal
            toCloseModal={closeModal}
            onSubmit={onSubmitVenueUpdate}
            onDelete={onDeleteVenue}
            venue={updateVenue}
          />
        ) : (
          <CreateVenueModal
            toCloseModal={closeModal}
            onSubmit={onSubmitVenueCreate}
          />
        )}
      </Modal>
    </Styled.Wrapper>
  );
};
