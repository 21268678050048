import { HttpServiceFactory } from '../../common/services';
import { EnhancedWithAuthHttpService } from '../../common/services/http-auth.service';
import { IDateRange, IVenueStatistic } from '../types';

export class StatisticService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}

  public getVenueStatistic({ from, to } : IDateRange) {
    const url = this.httpService.createQueryLink('statistics', {
      orderStatus: 'completed',
      orderItem: 'delivered',
      from,
      to,
    });

    return this.httpService.get<IVenueStatistic[]>(url);
  }

  public getTablesStatistic({ from, to } : IDateRange, venueId: string) {
    const url = this.httpService.createQueryLink('statistics', {
      selectObject: 'table',
      orderStatus: 'completed',
      orderItem: 'delivered',
      from,
      to,
      venueId,
    });

    return this.httpService.get<IVenueStatistic[]>(url);
  }

  public getWaitersStatistic({ from, to } : IDateRange, venueId: string) {
    const url = this.httpService.createQueryLink('statistics', {
      selectObject: 'waiter',
      orderStatus: 'completed',
      orderItem: 'delivered',
      from,
      to,
      venueId,
    });

    return this.httpService.get<IVenueStatistic[]>(url);
  }

  public getDeletedStatistic({ from, to } : IDateRange, venueId: string) {
    const url = this.httpService.createQueryLink('statistics', {
      selectObject: 'order',
      orderStatus: 'deleted',
      from,
      to,
      venueId,
    });

    return this.httpService.get<IVenueStatistic[]>(url);
  }
};

const factory = new HttpServiceFactory();
export const statisticService = new StatisticService(factory.createAuthHttpService());


