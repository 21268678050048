import * as Yup from 'yup';
import { getNameValidator } from '../../common/utils/get-name-validator';
import { getPriceValidator } from '../../common/utils/get-price-validatior';


export const validationSchema = Yup.object().shape({
  name: getNameValidator(),
  description: getNameValidator('Description'),
  media: Yup.string(),
  price: getPriceValidator(),
});
