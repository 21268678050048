export const ROUTES = {
  ROUTES_VENUES: 'venue',
  ROUTES_TABLES: 'table',
  ROUTES_MENU: 'menu',
  ROUTES_MENU_ITEM_OPTION: 'menu-item-option',
};

export const PASSWORD_MATCH =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&*])(?=.{8,})/;

export enum StoreKeys {
  Token = 'token',
}
