import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import qs from 'qs';
import { PrivateRoute } from './private-route';
import { Home } from '../modules/home';
import { ErrorPage } from '../modules/error';
import { VenuePage } from '../modules/management/venue-page.component';
import { Login } from '../modules/login';
import { MenuPage } from '../modules/menu';
import { SelectedVenuePage } from '../modules/management/selected-venue-page.component';
import { ConfigureDish } from '../modules/menu/components/configure-dish';
import { VenueStatisticsPage } from '../modules/statistics/venues-statistic-page.component';
import { TablesStatisticsPage } from '../modules/statistics/tables-statistic-page.component';
import { WaitersStatisticsPage } from '../modules/statistics/waiters-statistic-page.component';
import { DeletedStatisticsPage } from '../modules/statistics/deleted-statistic-page.component';
import { auth } from '../modules/common/services/auth.service';

export const MainRouter = () => {
  document.addEventListener('http-error',
      ({ detail }: CustomEventInit) => window.location.href=`/error?${qs.stringify(detail)}`,
  );

  return (
    <Router>
      <Switch>
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute path="/management/menu-item/:id" component={ConfigureDish} />
        <PrivateRoute path="/management/menu-item" component={ConfigureDish} />
        <PrivateRoute exact path="/management/:venueId/menu" component={MenuPage} />
        <PrivateRoute exact path="/management/:venueId" component={SelectedVenuePage} />
        <PrivateRoute exact path="/management" component={VenuePage} />
        <PrivateRoute exact path="/statistics" component={VenueStatisticsPage} />
        <PrivateRoute exact path="/statistics/:venueId/tables" component={TablesStatisticsPage} />
        <PrivateRoute exact path="/statistics/:venueId/waiters" component={WaitersStatisticsPage} />
        <PrivateRoute exact path="/statistics/:venueId/deleted" component={DeletedStatisticsPage} />
        <Route exact path="/login">
          {auth.checkIsTokenValid() ? <Redirect to="/" /> : <Login />}
        </Route>
        <Route component={ErrorPage} path="/error"/>
        <Route path="*">
          <ErrorPage />
        </Route>
      </Switch>
    </Router>
  );
};
